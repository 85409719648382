import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Contact`}</h2>
    <p>{`For van stuff, visit `}<a parentName="p" {...{
        "href": "https://www.greenstello.com"
      }}>{`greenstello.com`}</a>{` or find me on `}<a parentName="p" {...{
        "href": "https://www.instagram.com/agreenstellovan/?hl=en"
      }}>{`instagram`}</a>{`.
Otherwise, `}<a parentName="p" {...{
        "href": "mailto:natecostello@gmail.com"
      }}>{`email`}</a>{` or text if you got my number.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      