import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Is it me you're looking for?`}</h2>
    <p>{`Kiteboarder, recovering engineer, serial van converter, skier, epicurean. Mostly in that order. I know a little about motors, power conversion, and code.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      